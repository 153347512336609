import React, { Component } from "react";
import { Spring } from "react-spring";

class PayDepositOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showContent: false
    };
  }

  render() {
    return (
      <Spring
        from={{ height: "0%", translateY: this.props.locationY }}
        to={{ height: "100%", translateY: 0 }}
        //impl={TimingAnimation}
        //config={{ duration: 1000 }}
      >
        {styles => {
          return (
            <div
              style={{
                ...styles,
                transform: `translateY(${styles.translateY}px)`
              }}
              className="prize-overlay prize-overlay-deposit is-visible"
            >
              <Spring from={{ opacity: 0 }} to={{ opacity: 1 }} delay={350}>
                {styles => {
                  return (
                    <div
                      className="grid-holder grid-holder_fixed"
                      style={styles}
                    >
                      {this.props.children}
                    </div>
                  );
                }}
              </Spring>
            </div>
          );
        }}
      </Spring>
    );
  }
}

export default PayDepositOverlay;
