import React, { Component } from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import * as routes from "../../config";

class Wallet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevWallet: props.wallet
    };
  }

  componentWillReceiveProps(props) {
    if (props.newEarnings && props.newEarnings !== 0) {
      setTimeout(() => {
        this.setState({
          prevWallet: props.wallet - props.newEarnings
        });
      }, 100);
    }
  }

  render() {
    const { showWithdraw, showAddmore } = this.props;

    return (
      <div className="grid-item">
        <p className="grid-item_bottom font-S">
          You have
          <CountUp
            prefix=" $"
            decimals={2}
            decimal="."
            separator=","
            duration={this.props.newEarnings ? 1 : 0.001}
            start={this.state.prevWallet}
            end={this.props.wallet}
          />
          &nbsp;
          {showAddmore ? (
            <Link to={routes.DEPOSIT} className="font-S font-green">
              Add
            </Link>
          ) : null}
          {showWithdraw ? (
            <span className="grid-item_full">
              <Link to={routes.SETTINGS} className="font-S font-green">
                Settings
              </Link>
            </span>
          ) : null}
        </p>
      </div>
    );
  }
}

Wallet.propTypes = {
  showWithdraw: PropTypes.bool,
  showAddmore: PropTypes.bool
};

Wallet.defaultProps = {
  showWithdraw: true,
  showAddmore: true
};

export default Wallet;
