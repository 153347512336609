/* global paypal */
import React from "react";
import scriptLoader from "react-async-script-loader";
import { auth } from "../lib/firebase";
import { URL, PAYPAL } from "../config";

class PaypalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showButton: false
    };
  }

  createPaypalButton = () => {
    paypal.Button.render(
      {
        style: {
          label: "paypal",
          size: "large", // small | medium | large | responsive
          shape: "rect", // pill | rect
          color: "black", // gold | blue | silver | black
          tagline: false
        },
        env: PAYPAL.env,
        commit: true,
        payment: () => {
          return paypal.request
            .post(`${URL.server}/paypalCreatePayment`, {
              ammout: this.props.ammout,
              userid: auth.currentUser.uid
            })
            .then(function(res) {
              return res.id;
            });
        },

        onAuthorize: data => {
          const EXECUTE_URL = `${URL.server}/paypalExecutePayment`;

          const jsondata = {
            paymentID: data.paymentID,
            payerID: data.payerID,
            userid: auth.currentUser.uid,
            ammout: this.props.ammout
          };

          return paypal.request.post(EXECUTE_URL, jsondata).then(res => {
            console.log("Payment Complete!");
            this.props.onComplete();
          });
        }
      },
      "#paypalButton"
    );
  };

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ showButton: true });
      this.createPaypalButton();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const isLoadedButWasntLoadedBefore =
      !this.state.showButton && !this.props.isScriptLoaded && isScriptLoaded;

    if (isLoadedButWasntLoadedBefore) {
      if (isScriptLoadSucceed) {
        this.setState({ showButton: true });
        this.createPaypalButton();
      }
    }
  }

  render() {
    return <div id="paypalButton" />;
  }
}

export default scriptLoader("https://www.paypalobjects.com/api/checkout.js")(
  PaypalButton
);
