import React, { Component } from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";
import queryString from "query-string";
import { URL } from "../../config";
import { ReactTitle } from "react-meta-tags";

import withAuthorization from "../Session/withAuthorization";
import { firestore, auth } from "../../lib/firebase";
import Utils from "../../lib/utils";
import Wallet from "../Common/Wallet";
import PotLog from "../Common/PotLog";
import Overlay from "../Common/Overlay";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2
});

const connection =
  navigator.connection || navigator.mozConnection || navigator.webkitConnection;
const userAgent = navigator.userAgent;

class BetPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pot: 0,
      prevPot: 0,
      showOverlay: false,
      earning: 0,
      random: 0.0,
      overlayError: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const query = queryString.parse(this.props.location.search);
    if (query.sucess) {
      this.setState({
        showOverlay: true,
        earning: parseInt(query.sucess, 10)
      });

      window.history.pushState({}, document.title, "/");

      setTimeout(() => {
        this.setState({
          showOverlay: false,
          earning: 0
        });
      }, 2000);
    }

    firestore
      .collection("global")
      .doc("pot")
      .onSnapshot(doc => {
        this.setState({
          prevPot: this.state.pot,
          pot: doc.data().value + doc.data().locked
        });
      });
  }

  //TODO: more random progressive (ease?)
  randomNumber = () => {
    let l =
      Math.floor(Math.random() * (100 * 100 - 1 * 100) + 1 * 100) / (1 * 100);

    this.setState({
      random: l
    });

    this.interval = requestAnimationFrame(this.randomNumber);
  };

  getRandomNumberInterval = () => {
    requestAnimationFrame(this.randomNumber);
  };

  clearRandomNumberInterval = () => {
    window.cancelAnimationFrame(this.interval);
  };

  shouldOverlayDismiss = () => {
    this.setState({
      addToWallet: this.state.earning,
      earning: 0,
      overlayError: false
    });

    if (this.props.location.search !== "") {
      this.props.location.search = "";
      this.setState({
        showOverlay: false
      });
    }
  };

  bet = () => {
    if (
      this.state.showOverlay === true ||
      parseInt(this.props.wallet, 10) < 1
    ) {
      return;
    }

    this.setState({
      overlayError: false,
      showOverlay: true
    });

    this.getRandomNumberInterval();
    this.timerStart = new Date().getTime();

    fetch(`${URL.server}/betRun`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        bet: 1,
        userid: auth.currentUser.uid,
        connection,
        userAgent
      })
    })
      .then(response => {
        console.log(response);
        return response.json();
      })
      .then(data => {
        console.log("DATA");
        console.log(data);

        let timer = 2000 - (new Date().getTime() - this.timerStart);
        let dismissTime = timer > 0 ? timer : 100;
        // console.log("TIMER");
        // console.log(timer);
        // console.log(dismissTime);

        setTimeout(() => {
          this.setState({
            earning: data.earnings ? data.earnings : 0
          });

          this.clearRandomNumberInterval();

          setTimeout(
            () =>
              this.setState({
                showOverlay: false
              }),
            1500
          );

          setTimeout(this.shouldOverlayDismiss, 1900);
        }, dismissTime);
      })
      .catch(error => {
        console.log("Request failed", error);
        this.setState({
          overlayError: true
        });

        this.clearRandomNumberInterval();

        // Close modal
        setTimeout(() => {
          this.setState({
            showOverlay: false
          });
        }, 3000);
      });
  };

  render() {
    // console.log("Render: " + this.state.earning);
    return (
      <div>
        <ReactTitle
          title={`The Pot is $${Utils.roundAndFormat(this.state.pot)}`}
        />
        <div className="grid-holder grid-holder_fixed grid-holder_fixed-side">
          <div className="grid-item">
            <h1 className="grid-item_top font-L">
              The pot is {"\n"}
              <CountUp
                className="font-dollar"
                prefix="$"
                decimals={2}
                decimal="."
                separator=","
                duration={this.state.prevPot === 0 ? 1.3 : 0.5}
                start={this.state.prevPot}
                ref={view => (this._overCount = view)}
                end={this.state.pot}
              />
            </h1>
          </div>
          <div className="grid-item">
            <p className="grid-item_bottom">
              <button
                name="bet"
                onClick={this.bet}
                className={`font-L bet-button ${
                  this.props.wallet < 1 ? "bet-button_locked" : ""
                }`}
              >
                Give $1
              </button>
            </p>
          </div>

          <Wallet
            wallet={this.props.wallet}
            newEarnings={this.state.addToWallet}
          />
        </div>

        <span className="internal-fix">
          <PotLog />
        </span>

        <Overlay show={this.state.showOverlay} error={this.state.overlayError}>
          <div
            className="font-L font-white"
            style={{ fontVariantNumeric: "tabular-nums" }}
          >
            +
            {this.state.earning !== 0
              ? formatter.format(this.state.earning)
              : formatter.format(this.state.random)}
          </div>
        </Overlay>
      </div>
    );
  }
}

BetPage.contextTypes = {
  authUser: PropTypes.object
};

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(BetPage);
