//ROUTES
export const APP = "/";
export const LANDING = "/home";
export const ABOUT = "/about";
export const DEPOSIT = "/deposit";
export const SETTINGS = "/settings";
export const SIGN_UP = "/signup";

//Database
const liveConfig = {
  apiKey: "AIzaSyClmGkSFmMkfm2HuCWaBVC5kTzNkqMSses",
  authDomain: "betsystem-e9876.firebaseapp.com",
  databaseURL: "https://betsystem-e9876.firebaseio.com",
  projectId: "betsystem-e9876",
  storageBucket: "betsystem-e9876.appspot.com",
  messagingSenderId: "777174790719"
};

const mirrorConfig = {
  apiKey: "AIzaSyDBFKKhdfBxksBzh8rdTrjW0pRPS-DRd-g",
  authDomain: "thepot-dev.firebaseapp.com",
  databaseURL: "https://thepot-dev.firebaseio.com",
  projectId: "thepot-dev",
  storageBucket: "thepot-dev.appspot.com",
  messagingSenderId: "814132072990"
};

//Paypal
const paypalProd = {
  env: "production",
  id: {
    productionID:
      "AYlO7OSjcH26LBFE930VqBD5tDaoN0dhL8dCBKsQXXL-3DyvF2d4tczCGCMyz1-alYqlowNSztN2Oe4K"
  }
};

const paypalDev = {
  env: "sandbox",
  id: {
    sandboxID:
      "AV5t-4msjZVpzzDTv49KCzUzEx1qQq3FEVt8Vzm-VoQCJ1gWV92VT66f0-bOvT5etEKtYxziFCH__bRU"
  }
};

//Url
const url = {
  "mirror-development": {
    client: "https://localhost:3000",
    server: "http://localhost:5000/thepot-dev/us-central1"
  },
  "mirror-production": {
    client: "https://localhost:3000",
    server: "http://localhost:5000/thepot-dev/us-central1"
  },
  "live-development": {
    client: "https://localhost:3000",
    server: "http://localhost:5000/betsystem-e9876/us-central1"
  },
  "live-production": {
    client: "https://thepot.is",
    server: "https://us-central1-betsystem-e9876.cloudfunctions.net"
  }
};

export const FIREBASEKEY =
  process.env.REACT_APP_STAGE === "live" ? liveConfig : mirrorConfig;

export const PAYPAL =
  process.env.NODE_ENV === "production" ? paypalProd : paypalDev;

export const URL =
  url[`${process.env.REACT_APP_STAGE}-${process.env.NODE_ENV}`];
