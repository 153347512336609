import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import * as routes from "../../config";
import { auth, firestore } from "../../lib/firebase";

const settings = { timestampsInSnapshots: true };
firestore.settings(settings);

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        wallet: 0,
        bonus: 0
      };
    }

    componentDidMount() {
      auth.onAuthStateChanged(authUser => {
        if (!condition(authUser)) {
          this.props.history.push(routes.LANDING);
        } else {
          firestore
            .collection("users")
            .doc(auth.currentUser.uid)
            .onSnapshot(doc => {
              const wallet = doc.data().wallet;
              this.setState({
                wallet: wallet.money + wallet.bonus,
                bonus: wallet.bonus
              });
            });
        }
      });
    }

    render() {
      return this.context.authUser ? (
        <Component
          wallet={this.state.wallet}
          bonus={this.state.bonus}
          {...this.props}
        />
      ) : null;
    }
  }

  WithAuthorization.contextTypes = {
    authUser: PropTypes.object
  };

  return withRouter(WithAuthorization);
};

export default withAuthorization;
