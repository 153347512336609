import React, { Component } from "react";
import { auth } from "../../lib/firebase";
import { Link } from "react-router-dom";
import { firestore } from "../../lib/firebase";
import * as routes from "../../config";
import Utils from "../../lib/utils";
import { ReactTitle } from "react-meta-tags";

class SignUpPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logged: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    auth.onAuthStateChanged(user => {
      if (user) {
        window.location = "/";
        this.setState({
          pot: 0,
          logged: true
        });
      }
    });

    firestore
      .collection("global")
      .doc("pot")
      .get()
      .then(doc => {
        this.setState({
          pot: doc.data().value
        });
      });
  }

  _handleSingOut = () => {
    auth.signOut();
    this.setState({
      logged: false
    });
  };

  _handleClick = e => {
    if (Utils.isMobile()) {
      this.props.history.push("/popup");
    } else {
      Utils.popupCenter("/popup", "Connecting to PayPal", 400, 585);
    }

    e.preventDefault();
  };

  render() {
    return (
      <div>
        <ReactTitle title="The Pot - Sign up" />
        <div className="page--green">
          <header className="block">
            <div className="block-unit--three-fifths block-unit--indented-left">
              <Link to={routes.LANDING} className="type-M type--dark-alpha">
                The pot is
                <br />
                <span className="type-M type--white">
                  ${Utils.roundAndFormat(this.state.pot)}
                </span>
              </Link>
            </div>
            <div className="block-unit--one-fifth block-navigation--top">
              <nav className="block-multi--menu type-S">
                <span className="type--alpha">Have an account?</span>{" "}
                <a
                  href="/popup"
                  onClick={this._handleClick}
                  className="type-S type--dark-alpha"
                >
                  Sign in
                </a>
              </nav>
            </div>
          </header>
          <section className="block--top-spaced">
            <div className="block-unit\">
              <p className="type-L type--white">
                Welcome! This is going to be great!
              </p>
              <br />
            </div>
          </section>
          <section className="block--top-spaced">
            <div className="block-unit--one-fifth">
              <p className="type-S type--alpha">Before continuing</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
            <div className="block-unit--three-fifths">
              <ul className="type-M type--dark-alpha">
                <li>
                  Accounts are created using the <br className="hide-phone" />
                  "Connect with PayPal" integration.
                </li>
                <li>&nbsp;</li>
                <li>
                  You'll be redirected to PayPal <br className="hide-phone" />
                  to safely connect your account.
                </li>
                <li>&nbsp;</li>
                <li>
                  The only information we'll collect{" "}
                  <br className="hide-phone" />
                  and store is your name and email.
                </li>
                <li>&nbsp;</li>
                <li>
                  You won't be charged to sign up. <br className="hide-phone" />
                  Nothing to worry about.
                </li>
                <br />
                <br className="hide-mobile" />
                <br className="hide-mobile" />
                <br className="hide-mobile" />
              </ul>
            </div>
          </section>
        </div>
        <div className="page">
          <section className="block--top-spaced">
            <div className="block-unit">
              <a
                href="/popup"
                onClick={this._handleClick}
                className="type-L type--paypal"
              >
                Continue to PayPal
              </a>
            </div>
          </section>
          <section className="block--top-spaced">
            <div className="block-unit">
              <p className="type-S type--alpha">
                By continuing, you agree to our Terms & Privacy.
              </p>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default SignUpPage;
