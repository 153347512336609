import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import { FIREBASEKEY } from "../config";

if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASEKEY);
}

const auth = firebase.auth();
const firestore = firebase.firestore();

export { auth, firestore };
