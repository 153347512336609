import React, { Component } from "react";
import * as routes from "../../config";
import { Link } from "react-router-dom";
import { auth } from "../../lib/firebase";
import withAuthorization from "../Session/withAuthorization";
import { ReactTitle } from "react-meta-tags";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2
});

class Settings extends Component {
  /*constructor(props) {
    super(props);
  }*/

  _handleSingOut = e => {
    auth.signOut();

    e.preventDefault();
  };

  render() {
    const { wallet, bonus } = this.props;
    const availableValue = wallet - bonus;

    return (
      <div>
        <ReactTitle title="The Pot - Settings" />
        <div className="grid-holder grid-holder_side grid-holder_side-left">
          <div className="grid-item grid-item_top">
            <h1 className="font-S">Settings</h1>
            {"\n"}
            <ul className="font-XS hide-mobile">
              <li className="font-gray">Request Withdraw</li>
              {"\n"}
              <li className="font-gray">Activity</li>
              {"\n"}
              <li className="font-gray">Notifications</li>
              {"\n"}
            </ul>
          </div>
        </div>

        <div className="grid-holder grid-holder_sticky grid-holder_sticky-side">
          <div className="grid-item">
            <p className="grid-item_top font-S">&nbsp;</p>
            <Link
              to={routes.APP}
              className="grid-item_top grid-item_right font-S font-green"
            >
              Close
            </Link>
          </div>
        </div>

        <div className="grid-holder grid-holder_scroll grid-holder_fixed-side grid-holder_pad">
          <div className="grid-item">
            <p className="font-L hide-mobile">Withdraw</p>
            <p className="font-L hide-desktop">Withdraw</p>
            {"\n"}

            <p className="font-XS font-gray grid-important">
              Automated withdraws will be available soon! Meanwhile, send cash
              out requests to{" "}
              <span className="font-green">cashier@thepot.is</span>. There's a
              minimum of $8 per withdraw and you can't cash out bonuses.
            </p>

            <table className="grid-item grid-table">
              <tbody>
                <tr>
                  <td className="font-XS grid-table-mobile">Your balance</td>
                  <td className="font-XS font-dollar">{formatter.format(wallet)}</td>
                </tr>
                <tr>
                  <td className="font-XS  grid-table-mobile">Locked bonus</td>
                  <td className="font-XS font-dollar">
                    {this.props.bonus > 0 ? formatter.format(bonus) : "--"}
                  </td>
                </tr>
                <tr className="grid-table-total">
                  <td className="font-S">&nbsp;</td>
                </tr>
                <tr>
                  <td className="font-S  grid-table-mobile">Available</td>
                  <td className="font-S grid-total-result font-dollar">
                    {formatter.format(availableValue)}
                  </td>
                </tr>
                <tr>
                  <td className="font-S">&nbsp;</td>
                </tr>
              </tbody>
            </table>

            <p className="font-XS grid-important">
              <button
                className="font-green font-XS"
                onClick={this._handleSingOut}
              >
                Log out
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(Settings);
