import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import "./assets/css/main.css";
import registerServiceWorker from "./registerServiceWorker";
import withAuthentication from "./components/Session/withAuthentication";
import * as routes from "./config";
import AboutPage from "./components/Pages/About";
import HomePage from "./components/Pages/Home";
import BetPage from "./components/Pages/Bet";
import SignUpPage from "./components/Pages/SignUp";
import DepositPage from "./components/Pages/Deposit";
import SettingsPage from "./components/Pages/Settings";
import PopUp from "./components/Pages/PopUp";

const App = withAuthentication(() => (
  <BrowserRouter>
    <div>
      <Route exact path={routes.APP} component={BetPage} />
      <Route path={routes.SIGN_UP} component={SignUpPage} />
      <Route path={routes.DEPOSIT} component={DepositPage} />
      <Route path={routes.LANDING} component={HomePage} />
      <Route path={routes.ABOUT} component={AboutPage} />
      <Route path={routes.SETTINGS} component={SettingsPage} />
      <Route path="/popup" component={PopUp} />
    </div>
  </BrowserRouter>
));

ReactDOM.render(<App />, document.getElementById("root"));
registerServiceWorker();
