import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import withAuthorization from "../Session/withAuthorization";
import PaypalButton from "../PayPalButton";
import Wallet from "../Common/Wallet";
import PayDepositOverlay from "../Common/PayDeposit";
import { ReactTitle } from "react-meta-tags";

class Option extends React.PureComponent {
  render() {
    const { value, bonus } = this.props;
    return (
      <li {...this.props}>
        <button className="deposit-button font-L font-dollar" name="deposit" data={value}>
          ${value}{" "}
          <span className="deposit-button-bonus font-white">
            {bonus ? `+$${bonus} bonus` : ""}
          </span>
        </button>
      </li>
    );
  }
}

class DepositPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ammout: "8",
      bonus: "0"
    };
  }

  _getYPosition = targetY => {
    return targetY;
  };

  _handleClick = (event, ammout, bonus) => {
    const size = event.target.getBoundingClientRect();
    this.setState({
      locationY: this._getYPosition(size.y),
      ammout,
      bonus,
      showOverlay: true
    });
  };

  _closeOverlay = e => {
    this.setState({
      showOverlay: false
    });

    e.preventDefault();
  };

  _onComplete = () => {
    const addedMoney =
      parseInt(this.state.ammout, 10) + parseInt(this.state.bonus, 10);
    window.location = `/?sucess=${addedMoney}`;
  };

  render() {
    return (
      <div>
        <ReactTitle title="The Pot - Add Money" />
        <div className="grid-holder grid-holder_fixed">
          <div className="grid-item">
            <h2 className="grid-item_top font-S">Select amount</h2>

            <Link
              className="grid-item_top grid-item_right font-S font-green"
              to={{
                pathname: "/"
              }}
            >
              Close
            </Link>
          </div>
          <div className="grid-item_middle">
            <ul className="grid-list font-L grid-list-deposit">
              <Option onClick={e => this._handleClick(e, "8", "0")} value={8} />
              <div className="deposit-button-selected" />
              <Option
                onClick={e => this._handleClick(e, "16", "3")}
                value={16}
                bonus={3}
              />
              <div className="deposit-button-selected" />
              <Option
                onClick={e => this._handleClick(e, "24", "5")}
                value={24}
                bonus={5}
              />
              <div className="deposit-button-selected" />
              <Option
                onClick={e => this._handleClick(e, "40", "8")}
                value={40}
                bonus={8}
              />
              <div className="deposit-button-selected" />
            </ul>

            {this.state.showOverlay ? (
              <PayDepositOverlay locationY={this.state.locationY}>
                <div className="grid-item">
                  <h2 className="grid-item_top font-white font-S">
                    Amount Selected
                  </h2>

                  <button
                    onClick={this._closeOverlay}
                    className="grid-item_top grid-item_right font-S font-white-alpha"
                  >
                    Cancel
                  </button>
                </div>
                <div className="grid-item">
                  <div className="grid-item_middle">
                    {"\n"}
                    {"\n"}
                    {"\n"}
                    {"\n"}
                    <div className="font-L font-white">
                      ${this.state.ammout}
                    </div>
                    <p className="font-S font-white-alpha">
                      + ${this.state.bonus} deposit bonus
                    </p>
                  </div>
                </div>
                <div className="grid-item_bottom">
                  <PaypalButton
                    ammout={this.state.ammout}
                    onComplete={this._onComplete}
                  />
                  {/* <p className=" font-XS font-white">
                      Your new balance will be $44.00
                    </p> */}
                </div>
              </PayDepositOverlay>
            ) : null}
          </div>

          <Wallet
            wallet={this.props.wallet}
            showWithdraw={false}
            showAddmore={false}
          />
        </div>
      </div>
    );
  }
}

DepositPage.contextTypes = {
  authUser: PropTypes.object
};

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(DepositPage);
//export default DepositPage;
