import React, { Component } from "react";
import { firestore } from "../../lib/firebase";

const Log = props => {
  const { label, value } = props;

  if (value <= 0) {
    return null;
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2
  });

  return (
    <li className="type-S">
      <span className="type--alpha">{label}</span>
      <br />
      <span className="type--money">{formatter.format(value)}</span>
    </li>
  );
};

class PotLog extends Component {
  state = {
    all: 0,
    week: 0,
    day: 0,
    hour: 0
  };

  componentDidMount() {
    firestore
      .collection("global")
      .doc("biggestPayout")
      .onSnapshot(doc => {
        this.setState(doc.data());
      });
  }

  render() {
    return (
      <div className="block-unit--one-fifth block-prizes">
        <p className="type-S type--alpha">Biggest <br className="hide-desktop" />payouts</p>
        <ul>
          <Log label="All time" value={this.state.all} />
          <Log label="This week" value={this.state.week} />
          <span className="hide-phone">
            <Log label="Today" value={this.state.day} />
          </span>
          <Log label="Last Hour" value={this.state.hour} />
        </ul>
      </div>
    );
  }
}

// PotLog.propTypes = {
// };

// PotLog.defaultProps = {
// };

export default PotLog;
