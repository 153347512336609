import React from "react";
import PropTypes from "prop-types";
import { Transition } from "react-spring";

const Overlay = props => {
  const { show, error, children } = props;
  const overlayTypeClass = error
    ? "prize-overlay-error"
    : "prize-overlay-result ";

  return (
    <Transition
      from={{ opacity: 1, translateY: 100 }}
      enter={{ opacity: 1, translateY: 0 }}
      leave={{ opacity: 1, translateY: 110 }}
    >
      {show
        ? styles => (
            <div
              style={{
                ...styles,
                transform: `translateY(${styles.translateY}%)`
              }}
              //WHEN ERROR: REPLACE prize-overlay-result WITH prize-overlay-error AND EVERYTHING WILL ADAPT ACCORDINGLY.

              className={`prize-overlay is-visible ${overlayTypeClass}`}
            >
              <div className="prize-overlay-result-holder">
                <div className="prize-overlay-loading loader">
                  <span className="loader_outer">
                    <span className="loader_inner">&nbsp;</span>
                  </span>
                </div>
                {children}
              </div>

              <div className="prize-overlay-error-holder">
                <span className="hide-mobile">
                  <h4 className="font-M font-white">
                    Something
                    <br />
                    went wrong!
                  </h4>
                </span>
                <span className="hide-desktop">
                  <h4 className="font-L font-white">
                    Something
                    <br />
                    went wrong!
                  </h4>
                </span>
                <p className="font-XS font-white font-alpha">
                  <br />
                  We weren't able to run The Pot.
                  <br />
                  You have not been charged.
                </p>
              </div>
            </div>
          )
        : () => null}
    </Transition>
  );
};

Overlay.propTypes = {
  show: PropTypes.bool,
  error: PropTypes.bool
};

Overlay.defaultProps = {
  show: false,
  error: false
};

export default Overlay;
