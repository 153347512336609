import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { URL } from "../../config";

class PopUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      innerText: ""
    };
  }
  /**
   * Returns the value of the given URL query parameter.
   */
  getURLParameter = name => {
    return (
      decodeURIComponent(
        (new RegExp("[?|&]" + name + "=([^&;]+?)(&|#|;|$)").exec(
          window.location.search
        ) || [null, ""])[1].replace(/\+/g, "%20")
      ) || null
    );
  };

  /**
   * This callback is called by the JSONP callback of the 'token' Firebase Function with the Firebase auth token.
   */

  componentDidMount() {
    window.tokenReceived = data => {
      console.log("tokenReceived");
      if (data.token) {
        firebase
          .auth()
          .signInWithCustomToken(data.token)
          .then(function() {
            window.close();
          });
      } else {
        this.setState({
          innerText: "Error in the token Function: " + data.error
        });
      }
    };

    // firebase.auth().onAuthStateChanged(user => {
    //   console.log(user);
    // });

    let code = this.getURLParameter("code");
    let state = this.getURLParameter("state");
    let error = this.getURLParameter("error");
    if (error) {
      this.setState({
        innerText: "Error back from the Paypal auth page: " + error
      });
    } else if (!code) {
      // Start the auth flow.
      window.location.href = URL.server + "/paypalRedirectUrl";
    } else {
      console.log("Callback");
      // Use JSONP to load the 'token' Firebase Function to exchange the auth code against a Firebase custom token.
      const script = document.createElement("script");
      script.type = "text/javascript";
      // This is the URL to the HTTP triggered 'token' Firebase Function.
      // See https://firebase.google.com/docs/functions.
      var tokenFunctionURL = URL.server + "/paypalGetUserInfos";
      script.src =
        tokenFunctionURL +
        "?code=" +
        encodeURIComponent(code) +
        "&state=" +
        encodeURIComponent(state) +
        "&callback=tokenReceived";

      document.head.appendChild(script);
    }
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          minHeight: "100%",
          backgroundColor: "#32D9A7",
          position: "absolute"
        }}
        className="grid-green"
      >
        {this.state.innerText}
      </div>
    );
  }
}

export default PopUp;
