import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as routes from "../../config";
import { firestore } from "../../lib/firebase";
import { auth } from "../../lib/firebase";
import Utils from "../../lib/utils";
import { ReactTitle } from "react-meta-tags";

class AboutPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logged: false,
      pot: 0
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    auth.onAuthStateChanged(user => {
      if (user) {
        window.location = "/";
        this.setState({
          logged: true
        });
      }
    });

    firestore
      .collection("global")
      .doc("pot")
      .get()
      .then(doc => {
        this.setState({
          pot: doc.data().value + doc.data().locked
        });
      });
  }

  _handleClick = e => {
    if (Utils.isMobile()) {
      this.props.history.push("/popup");
    } else {
      Utils.popupCenter("/popup", "Connecting to PayPal", 400, 585);
    }

    e.preventDefault();
  };

  render() {
    return (
      <div>
        <ReactTitle title="The Pot - About" />
        <div className="page--dark">
          <header className="block">
            <div className="block-unit--three-fifths block-unit--indented-left">
              <Link to={routes.LANDING} className="type-M type--white">
                The pot is <br />
                <span className="type-M type--money">
                  ${Utils.roundAndFormat(this.state.pot)}
                </span>
              </Link>
            </div>
            <div className="block-unit--one-fifth block-navigation--top">
              <nav className="block-multi--menu">
                <Link to={routes.SIGN_UP} className="type-S type--green">
                  Create account
                </Link>
                <a
                  href="/popup"
                  onClick={this._handleClick}
                  className="type-S type--green"
                >
                  Sign in
                </a>
              </nav>
            </div>
          </header>
          <section className="block--top-spaced type--white">
            <div className="block">
              <div className="block-unit--one-half">
                <h4 className="type-L">First of all, why?</h4>
              </div>
              <div className="block-unit--one-half">&nbsp;</div>
            </div>
            <div className="block">
              <div className="block-unit--one-half">
                <p className="type-M type--alpha">
                  <br className="hide-mobile" />
                  This is an experiment about trying to bring a new perspective
                  to an industry burried in complexity and unfairness.
                  <br />
                  <br />
                  When we first discussed the idea, the amount of challenges to
                  overcome - both technical and financial - sounded like
                  something that would be super fun to design and bring to life.
                  <br />
                  <br />
                  As the concept, in it's early days, generated a lot of
                  interest from our network, we decided to pursue it and see
                  where it takes us from there.
                  <span className="hide-desktop">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                </p>
              </div>
              <div className="block-unit--one-half">
                <br className="hide-mobile" />
                <h4 className="type-L">
                  What is The Pot? Is it a slot machine?
                </h4>
              </div>
            </div>
            <div className="block">
              <div className="block-unit--one-half">&nbsp;</div>
              <div className="block-unit--one-half">
                <p className="type-M type--alpha">
                  <br className="hide-mobile" />
                  Well, no. Even though the ideia might share similarities, we
                  think of The Pot as a fair, real-time micro sweepstakes. We
                  built it to be fair. Completely random.
                  <br />
                  <br />
                  There's no bias towards the "house" and there's no "playing
                  against the odds". In fact, there's no house. It's way simpler
                  then that: Give money, receive money back at random.
                  <span className="hide-desktop">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                </p>
              </div>
            </div>
            <div className="block">
              <div className="block-unit--one-half">
                <h4 className="type-L">Can I win the pot, then?</h4>
              </div>
              <div className="block-unit--one-half">&nbsp;</div>
            </div>
            <div className="block">
              <div className="block-unit--one-half">
                <p className="type-M type--alpha">
                  <br className="hide-mobile" />
                  Absolutely. Every time you give money, you get money back and
                  the chances are always 100% random. Simple mechanics, real
                  chances, all the time, for everyone.
                  <br />
                  <br />
                </p>
                <p className="type-M type--white">
                  <Link to={routes.SIGN_UP} className="type-M type--green">
                    Sign up
                  </Link>{" "}
                  <span className="type--alpha">
                    and get $2 free to start! <br className="hide-mobile" />
                    No deposit required.
                  </span>
                  <span className="hide-desktop">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                </p>
              </div>
              <div className="block-unit--one-half">
                <br className="hide-mobile" />
                <h4 className="type-L">Do you make money out of it?</h4>
              </div>
            </div>
            <div className="block">
              <div className="block-unit--one-half">&nbsp;</div>
              <div className="block-unit--one-half">
                <p className="type-M type--alpha">
                  <br className="hide-mobile" />
                  No, we are not making any money. At least for now. We don't
                  take any cuts from payments or prizes and we don't charge any
                  fees for depositing or withdrawing money. As for now it is a
                  simple experiment for everyone to have fun.
                  <span className="hide-desktop">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                </p>
              </div>
            </div>
            <div className="block">
              <div className="block-unit--one-half">
                <h4 className="type-L">So where do you keep the money?</h4>
              </div>
              <div className="block-unit--one-half">&nbsp;</div>
            </div>
            <div className="block">
              <div className="block-unit--one-half">
                <p className="type-M type--alpha">
                  <br className="hide-mobile" />
                  It's all safely handled on PayPal. It never leaves The Pot
                  PayPal account, unless withdraws are requested by the users.
                  <span className="hide-desktop">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                </p>
              </div>
              <div className="block-unit--one-half">
                <br className="hide-mobile" />
                <h4 className="type-L">
                  And what are the odds to win the pot?
                </h4>
              </div>
            </div>
            <div className="block">
              <div className="block-unit--one-half">&nbsp;</div>
              <div className="block-unit--one-half">
                <p className="type-M type--alpha">
                  <br className="hide-mobile" />
                  Mathematically speaking - because we built our algorithm to be
                  completely random - there's no way to define fixed odds to get
                  the pot. The chances are always based on ratio relative to the
                  total amount available.
                  <br />
                  <br />
                  After running millions of simulations for a $1,000.00 pot, for
                  example, we got to 1:14 (6.6667%) odds to receive more than $1
                  and 1:19980 (0.005%) odds to receive the entire pot.
                </p>
              </div>
            </div>
          </section>
          <section className="block--top-spaced">
            <div className="block-unit">
              <h2 className="type-L type--white">
                <Link to={routes.SIGN_UP} className="type-L type--green">
                  Sign up
                </Link>{" "}
                and get
                <br className="hide-mobile" /> $2 free{" "}
                <br className="hide-desktop" />
                to start.
              </h2>
              <p className="type-S type--white type--alpha">
                <br />
                No deposit is required!
              </p>
            </div>
          </section>
          <footer className="block--top-spaced block-footer">
            <div className="block-unit--one-fifth">
              <p className="type-S type--white type--alpha hide-mobile">
                The Pot Group
              </p>
            </div>
            <div className="block-unit--three-fifths">
              <nav className="block-multi--menu">
                <Link to={routes.LANDING} className="type-S type--green">
                  Home
                </Link>
                <Link to={routes.SIGN_UP} className="type-S type--green">
                  Create account
                </Link>
              </nav>
            </div>
            <div className="block-unit--one-fifth">
              <p className="type-S type--white type--alpha">
                {new Date().getFullYear()} All rights reserved.
              </p>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default AboutPage;
