import React, { Component } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../lib/firebase";
import { firestore } from "../../lib/firebase";
import * as routes from "../../config";
import Utils from "../../lib/utils";
import CountUp from "react-countup";
import MetaTags from "react-meta-tags";
import PotLog from "../Common/PotLog";

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pot: 0,
      prevPot: 0
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    firestore
      .collection("global")
      .doc("pot")
      .onSnapshot(doc => {
        this.setState({
          pot: doc.data().value + doc.data().locked
        });
      });

    auth.onAuthStateChanged(user => {
      if (user) {
        window.location = "/";
        this.setState({
          logged: true
        });
      }
    });
  }

  _handleClick = e => {
    if (Utils.isMobile()) {
      this.props.history.push("/popup");
    } else {
      Utils.popupCenter("/popup", "Connecting to PayPal", 400, 585);
    }

    e.preventDefault();
  };

  render() {
    return (
      <div>
        <MetaTags>
          <title>The Pot is ${Utils.roundAndFormat(this.state.pot)}</title>
          <meta
            property="og:site_name"
            content={`The Pot is $${Utils.roundAndFormat(this.state.pot)}`}
          />
        </MetaTags>

        <div className="page page--fixed-bottom">
          <header className="block">
            <div className="block-unit--three-fifths block-unit--indented-left">
              <p className="type-M">
                The pot is
                <br />
                <CountUp
                  className="type-M type--money"
                  prefix="$"
                  decimals={2}
                  duration={1.5}
                  decimal="."
                  separator=","
                  start={this.state.prevPot}
                  end={this.state.pot}
                />
              </p>
            </div>
            <div className="block-unit--one-fifth block-navigation--top">
              <nav className="block-multi--menu">
                <Link to={routes.SIGN_UP} className="type-S type--green">
                  Create account
                </Link>
                <a
                  href="/popup"
                  onClick={this._handleClick}
                  className="type-S type--green"
                >
                  Sign in
                </a>
              </nav>
            </div>
          </header>
          <section className="block--top-spaced">
            <div className="block-unit">
              <h1 className="type-L">Give $1, win money back. Every time.</h1>
            </div>
          </section>
          <section className="block--top-spaced">
            <div className="block-unit--one-fifth">
              <p className="type-S type--alpha">Here's how</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
            <div className="block-unit--three-fifths">
              <ul className="type-M">
                <li>
                  You always give
                  <br />
                  $1 at a time.
                </li>
                <li>&nbsp;</li>
                <li>
                  Every dollar is added
                  <br />
                  to the pot. In real-time.
                </li>
                <li>&nbsp;</li>
                <li>
                  After you give, the pot
                  <br />
                  gives you money back.
                </li>
                <li>&nbsp;</li>
                <li>
                  You can get from 1¢
                  <br />
                  to the entire pot!
                </li>
                <li>&nbsp;</li>
                <li>
                  And the pot is <br />
                  <span className="type--money">
                    ${Utils.roundAndFormat(this.state.pot)}
                  </span>{" "}
                  right now.
                </li>
              </ul>
            </div>

            <PotLog />
          </section>
          <section className="block--top-spaced">
            <div className="block-unit">
              <h2 className="type-L">
                <Link to={routes.SIGN_UP} className="type-L type--green">
                  Sign up
                </Link>{" "}
                and get
                <br className="hide-mobile" /> $2 free{" "}
                <br className="hide-desktop" />
                to start.
              </h2>
              <p className="type-S type--alpha">
                <br />
                No deposit is required!
              </p>
            </div>
            <div className="block-unit--indented-left" />
          </section>
          <footer className="block--top-spaced block-footer">
            <div className="block-unit--one-fifth">
              <p className="type-S type--alpha hide-mobile">The Pot Group</p>
              <p className="type-S type--alpha hide-desktop hide-phone">
                TP Group
              </p>
            </div>
            <div className="block-unit--three-fifths">
              <nav className="block-multi--menu">
                <Link to={routes.SIGN_UP} className="type-S type--green">
                  Create account
                </Link>
                <Link to={routes.ABOUT} className="type-S type--green">
                  About
                </Link>
              </nav>
            </div>
            <div className="block-unit--one-fifth">
              <p className="type-S type--alpha">
                {new Date().getFullYear()} All rights reserved.
              </p>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default HomePage;
